<template>
  <b-row>
    <b-col
      cols="12"
      md="9"
    >
      <component :is="orgData === undefined ? 'div' : 'b-card'">

        <!-- Alert: No item found -->
        <b-alert
          variant="danger"
          :show="orgData === undefined"
        >
          <h4 class="alert-heading">
            Error fetching org data
          </h4>
          <div class="alert-body">
            No org found with this org id. Check
            <b-link
              class="alert-link"
              :to="{ name: 'apps-orgs-list'}"
            >
              Org List
            </b-link>
            for other orgs.
          </div>
        </b-alert>

        <org-edit-tab-account
          v-if="orgData"
          :org-data="orgData"
        />
      </component>
    </b-col>
    <b-col
      cols="12"
      md="3"
    >
      <!-- PERMISSION TABLE -->
      <permissions-groups-card
        v-if="orgData"
        :permissions="orgData.permissions"
        :can-view="orgData.can_view"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BRow, BCol
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import OrgEditTabAccount from './OrgEditTabAccount.vue'
import PermissionsGroupsCard from '../../../layouts/components/PermissionGroupsCard.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,

    OrgEditTabAccount,
    PermissionsGroupsCard,

  },
  setup() {
    const orgData = ref(null)

    store.dispatch('app/fetchOrg', { id: router.currentRoute.params.id })
      .then(response => { orgData.value = response.data.results[0] })
      .catch(error => {
        if (error.response.status === 404) {
          orgData.value = undefined
        }
      })

    return {
      orgData,
    }
  },
}
</script>

<style>

</style>
